import PinDropIcon from '@mui/icons-material/PinDrop';
import MapIcon from '@mui/icons-material/Map';
import PercentIcon from '@mui/icons-material/Percent';
import MapsUgcIcon from '@mui/icons-material/MapsUgc';
import CategoryIcon from '@mui/icons-material/Category';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import ReceiptIcon from '@mui/icons-material/Receipt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonIcon from '@mui/icons-material/Person';
import PaymentsIcon from '@mui/icons-material/Payments';
// import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
// import GroupAddIcon from '@mui/icons-material/GroupAdd';
// import BackupIcon from '@mui/icons-material/Backup';
import SettingsIcon from '@mui/icons-material/Settings';


export const menuItems = [
	{
		name: "Setup",
		icon: <SettingsIcon />,
		requiredPermissions: [
			process.env.REACT_APP_WRITE_ZONES_CLAIM,
			process.env.REACT_APP_WRITE_SECTIONS_CLAIM,
			process.env.REACT_APP_WRITE_VAT_CLAIM
		],
		subItems: [
			{ name: "Zone", icon: <PinDropIcon />, requiredPermissions: [process.env.REACT_APP_READ_ZONES_CLAIM] },
			{ name: "Section", icon: <MapsUgcIcon />, requiredPermissions: [process.env.REACT_APP_READ_SECTIONS_CLAIM] },
			{ name: "VAT", icon: <PercentIcon />, requiredPermissions: [process.env.REACT_APP_READ_VAT_CLAIM] },
			{ name: "Customer Sales Rep", icon: <ContactPageIcon />, requiredPermissions: [process.env.REACT_APP_WRITE_CUSTOMER_SALES_REP_PERMISSION] },
			{ name: "Payment term", icon: <PaymentsIcon />, requiredPermissions: [process.env.REACT_APP_WRITE_PAYMENT_TERM_CLAIM] }
		]
	},
	{
		name: "Inventory",
		icon: <CategoryIcon />,
		requiredPermissions: [
			process.env.REACT_APP_WRITE_INVENTORY_CLAIM,
			process.env.REACT_APP_READ_INVENTORY_CLAIM,
			process.env.REACT_APP_WRITE_INVENTORY_CATEGORIES_CLAIM,
			process.env.REACT_APP_WRITE_INVENTORY_TAGS_CLAIM,
			process.env.REACT_APP_WRITE_INVENTORY_SUPPLIERS_CLAIM,
			process.env.REACT_APP_DELETE_INVENTORY_ITEMS,
		],
		subItems: [
			{ name: "Item List", icon: <MapIcon />, requiredPermissions: [process.env.REACT_APP_READ_INVENTORY_CLAIM] },
			{ name: "Item Tags", icon: <LocalOfferIcon />, requiredPermissions: [process.env.REACT_APP_READ_INVENTORY_TAGS_CLAIM] },
			{ name: "Item Categories", icon: <CategoryIcon />, requiredPermissions: [process.env.REACT_APP_READ_INVENTORY_CATEGORIES_CLAIM] },
			{ name: "Item Suppliers", icon: <LocalShippingIcon />, requiredPermissions: [process.env.REACT_APP_WRITE_INVENTORY_SUPPLIERS_CLAIM] },
			{ name: "Supplier Invoices", icon: <ReceiptIcon />, requiredPermissions: [process.env.REACT_APP_WRITE_INVENTORY_SUPPLIERS_CLAIM] }
		]
	},
	{
		name: "Customer",
		icon: <ContactPageIcon />,
		requiredPermissions: [
			process.env.REACT_APP_WRITE_CUSTOMERS_CLAIM,
			process.env.REACT_APP_WRITE_CUSTOMER_ZONES_CLAIM,
			process.env.REACT_APP_WRITE_CUSTOMER_TAGS_CLAIM,
		],
		subItems: [
			{ name: "Customer", icon: <ContactPhoneIcon />, requiredPermissions: [process.env.REACT_APP_READ_CUSTOMERS_CLAIM] },
			{ name: "Accounts", icon: <AccountBalanceIcon />, requiredPermissions: [process.env.REACT_APP_WRITE_INVOICE_PAYMENTS_DATA_CLAIM] },
			{ name: "Set Customer Zone", icon: <PinDropIcon />, requiredPermissions: [process.env.REACT_APP_WRITE_CUSTOMER_ZONES_CLAIM] },
			{ name: "Set Customer Zone V3", icon: <PinDropIcon />, requiredPermissions: [process.env.REACT_APP_WRITE_CUSTOMER_ZONES_CLAIM] },
			{ name: "Customer Tags", icon: <LocalOfferIcon />, requiredPermissions: [process.env.REACT_APP_READ_CUSTOMER_TAGS_CLAIM] }
		]
	},
	{
		name: "Driver",
		icon: <LocalShippingIcon />,
		requiredPermissions: [
			process.env.REACT_APP_WRITE_DRIVER_DETAILS_PERMISSION,
		],
		subItems: [
			{ name: "Name", icon: <ContactPhoneIcon />, requiredPermissions: [process.env.REACT_APP_WRITE_DRIVER_DETAILS_PERMISSION] },
			{ name: "Vehicle", icon: <LocalShippingIcon />, requiredPermissions: [process.env.REACT_APP_WRITE_DRIVER_DETAILS_PERMISSION] },
			{ name: "Totals", icon: <ReceiptIcon />, requiredPermissions: [process.env.REACT_APP_WRITE_DRIVER_TOTALS_PERMISSION] },
		]
	},
	{
		name: "Invoice",
		icon: <ReceiptIcon />,
		requiredPermissions: [
			process.env.REACT_APP_READ_INVOICES_CLAIM,
			process.env.REACT_APP_READ_INVOICE_MARGINS_CLAIM,
			process.env.REACT_APP_WRITE_INVOICE_PAYMENTS_DATA_CLAIM,
			process.env.REACT_APP_WRITE_IN_PERSON_INVOICES_CLAIM
		],
		subItems: [
			{ name: "Order Taking Sheet", icon: <ReceiptIcon />, requiredPermissions: [process.env.REACT_APP_WRITE_INVOICES_CLAIM] },
			{ name: "Manage Invoices", icon: <ReceiptIcon />, requiredPermissions: [process.env.REACT_APP_READ_INVOICES_CLAIM] },
			{ name: "Invoices(limited)", icon: <ReceiptIcon />, requiredPermissions: [process.env.REACT_APP_READ_INVOICES_LIMITED_CLAIM] },
			{ name: "Collections", icon: <ReceiptIcon />, requiredPermissions: [process.env.REACT_APP_WRITE_IN_PERSON_INVOICES_CLAIM] }
		]
	},
	{
		name: "Admin",
		icon: <ManageAccountsIcon />,
		requiredPermissions: [process.env.REACT_APP_MANAGE_USERS_PERMISSION],
		subItems: [
			{ name: "Manage Users", icon: <PersonIcon />, requiredPermissions: [process.env.REACT_APP_MANAGE_USERS_PERMISSION] },
			// { name: "Manage Users", icon: <GroupAddIcon /> },
			// { name: "Manage User Roles", icon: <SupervisedUserCircleIcon /> },
			// { name: "Backup", icon: <BackupIcon /> }
		]
	}
];
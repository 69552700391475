import React, {useEffect, useState} from 'react';

import displaySnackState from "../../components/customisedSnackBar/DisplaySnackState";
import CustomisedSnackBar from "../../components/customisedSnackBar/CustomisedSnackBar";
import {
    currentUserHasPermissions,
    daysMap,
    defaultLoadedFieldData,
    defaultSnackState,
    fetchAllEntriesAndSetRowData,
    fetchDropdownField
} from "../../components/formFunctions/FormFunctions";

import {Button, Grid, Input, InputLabel, Select} from "@mui/material";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import axiosDefault from "../../components/axiosDefault/axiosDefault";
import {URL_API, URL_ROOT} from "../../configs/config";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Card from "@mui/material/Card";
import CircleIcon from "@mui/icons-material/Circle";

const API_NAME = '/customer';

export const SetCustomerZones = () => {
    const [sendingData, setSendingData] = useState(false);
    const [zonesData, setZonesData] = useState(defaultLoadedFieldData);
    const [snackState, setSnackState] = useState(defaultSnackState);
    const [allCustomersData, setAllCustomersData] = useState([]);
    const [selectedDay, setSelectedDay] = useState(0);
    const [selectedZone, setSelectedZone] = useState(null);
    const [availableCustomers, setAvailableCustomers] = useState([]);
    const [mappedCustomers, setMappedCustomers] = useState([]);
    const [availableCustomersFilterValue, setAvailableCustomersFilterValue] = useState("");
    const [mappedCustomersFilterValue, setMappedCustomersFilterValue] = useState("");
    const [removedCustomers, setRemovedCustomers] = useState([]);
    const requiredWritePermissions = [process.env.REACT_APP_WRITE_CUSTOMER_ZONES_CLAIM];

    const setAllDisplayedCustomers = () => {
        setAvailableCustomers(allCustomersData.filter(customer => customer.delivery_order[selectedDay] === null || customer.zones[selectedDay] === null || customer.zones[selectedDay].toString() !== selectedZone));
        setMappedCustomers(allCustomersData
            .filter(customer => customer.delivery_order[selectedDay] !== null && customer.zones[selectedDay] !== null && customer.zones[selectedDay].toString() === selectedZone)
            .sort((customerA, customerB) =>
                customerA.delivery_order[selectedDay] > customerB.delivery_order[selectedDay] ? 1 :
                    customerA.delivery_order[selectedDay] < customerB.delivery_order[selectedDay] ? -1 : 0
            )
        );
    };

    const handleDayChange = event => {
        const newSelectedDay = event.target.value;
        setSelectedDay(newSelectedDay);
    };

    const handleZoneChange = event => {
        const newSelectedZone = event.target.value;
        setSelectedZone(newSelectedZone);
    };

    const fetchZones = () => {
        fetchDropdownField("/zone", setZonesData, setSnackState, false);
    };

    const fetchAllItems = () => {
        fetchAllEntriesAndSetRowData(API_NAME, null, setSendingData, setAllCustomersData, setSnackState);
        fetchZones();
        setAllDisplayedCustomers();
    };

    useEffect(() => {
        fetchAllItems();
    }, []);

    useEffect(() => {
        setAllDisplayedCustomers();
    }, [selectedDay, selectedZone, allCustomersData]);

    const saveChanges = () => {
        const API_UPDATE_ZONE_DELIVERY = "/updateCustomerZoneDelivery";
        const updatedCustomers = mappedCustomers.map((customer, index) => {
            let newZones = [...customer.zones];
            newZones[selectedDay] = selectedZone;
            let delivery_order = [...customer.delivery_order];
            delivery_order[selectedDay] = index + 1;

            return {
                _id: customer._id,
                zones: newZones,
                delivery_order: delivery_order
            }
        });
        const payload = {
            updatedCustomers: updatedCustomers,
            removedCustomers: removedCustomers
        }
        axiosDefault().post(URL_ROOT + URL_API + API_NAME + API_UPDATE_ZONE_DELIVERY, payload)
            .then(response => {
                displaySnackState("Successfully updated", "success", setSnackState);
            })
            .catch(error => {
                console.error(error);
                displaySnackState(`Failed to update - ${error.response ? error.response.data : error.message}`, "error", setSnackState);
            })
            .finally(() => {
                fetchAllItems();
            })
    };

    const findCustomerByID = id => {
        return allCustomersData.find(customer => customer._id.toString() === id);
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = [...list];
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const handleOnDragEnd = result => {
        const { source, destination, draggableId } = result;

        if (!destination) {
            return;
        }

        // Sorting in same list
        if (source.droppableId === destination.droppableId && destination.droppableId === "ZoneOrder") {
            //rearrange mapped customers accordingly
            const items = reorder(
                mappedCustomers,
                source.index,
                destination.index
            );
            setMappedCustomers(items);
        } else if(source.droppableId === destination.droppableId && destination.droppableId === "Customers") {
            return;
        } else if(source.droppableId === "Customers" && destination.droppableId === "ZoneOrder"){//moving from customers to mapped zone order
            const newMappedCustomers = [...mappedCustomers];
            newMappedCustomers.splice(destination.index, 0, findCustomerByID(draggableId));

            const newAvailableCustomers = [...availableCustomers];
            newAvailableCustomers.splice(source.index, 1);

            setMappedCustomers(newMappedCustomers);
            setAvailableCustomers(newAvailableCustomers);
        } else {//unmap and track
            const newAvailableCustomers = [...availableCustomers];
            const unmappedCustomer = findCustomerByID(draggableId);
            newAvailableCustomers.splice(destination.index, 0, unmappedCustomer);

            const newMappedCustomers = [...mappedCustomers];
            newMappedCustomers.splice(source.index, 1);

            const unmappedZones = [...unmappedCustomer.zones];
            unmappedZones[selectedDay] = null;
            const unmappedDelivery = [...unmappedCustomer.delivery_order];
            unmappedDelivery[selectedDay] = null;
            setRemovedCustomers([...removedCustomers, {
                _id: unmappedCustomer._id,
                zones: unmappedZones,
                delivery_order: unmappedDelivery
            }]);
            setAvailableCustomers(newAvailableCustomers);
            setMappedCustomers(newMappedCustomers);
        }
    };

    const cardStyles = {
        width: "30%"
    };

    const cardContainerStyles = {
        width: "90%",
        minWidth: "90%",
        height: "100%",
        overflowY: "auto"
    };

    const gridItemStyle = {
        maxHeight: "75vh",
        minWidth: "30vw",
        borderRight: "1px solid"
    };

    const availableCustomersFilterChange = event => {
        const {value} = event.target;
        setAvailableCustomersFilterValue(value);
    };

    const mappedCustomersFilterChange = event => {
        const {value} = event.target;
        setMappedCustomersFilterValue(value);
    };

    const getAvailableCustomersList = () => {
        return availableCustomers.sort((customerA, customerB) =>
            customerA.order_taking_days.includes(selectedDay) && customerB.order_taking_days.includes(selectedDay) ? 0 :
                customerA.order_taking_days.includes(selectedDay) ? -1 : 1
        );
    };

    return <div style={{height: "90%"}}>
        <CustomisedSnackBar {...snackState} setClosed={setSnackState} />
        <div style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        }}>
            <FormControl sx={{ m: 1, minWidth: 80 }}>
                <InputLabel>Day</InputLabel>
                <Select
                    label="Day"
                    onChange={handleDayChange}
                    value={selectedDay}
                >
                    {
                        daysMap.map((day, index) =>
                            <MenuItem value={index}>{day}</MenuItem>
                        )
                    }
                </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 80 }}>
                <InputLabel>Zone</InputLabel>
                <Select
                    label="Zone"
                    onChange={handleZoneChange}
                    value={selectedZone}
                >
                    {zonesData.menuEntries}
                </Select>
            </FormControl>
            <Button variant="contained" onClick={saveChanges}style={{marginRight: "10em"}} disabled={!currentUserHasPermissions(requiredWritePermissions)}>Save Changes</Button>
            <Button variant="contained" onClick={fetchAllItems}>Reload</Button>
        </div>

        {!selectedZone ? <h1>Select a zone</h1> :
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Grid container spacing={{xs: 2}} columns={{xs: 3}} style={{height: "fit-content"}}>
                    <Grid item style={gridItemStyle}>
                        <p>Available Customers</p>
                        <Input placeholder={"Filter"} onChange={availableCustomersFilterChange} value={availableCustomersFilterValue} />
                        <Droppable droppableId="Customers">
                            {(provided) => (
                                <div style={cardContainerStyles} {...provided.droppableProps} ref={provided.innerRef}>
                                    {getAvailableCustomersList().map(({_id, customer_name, zones}, index) => {
                                        return (
                                            <Draggable key={_id} draggableId={_id} index={index}>
                                                {(provided) => (
                                                    <Card
                                                        variant="outlined"
                                                        hidden={availableCustomersFilterValue.length ? !customer_name.toLowerCase().includes(availableCustomersFilterValue.toLowerCase()) : false}
                                                        style={cardStyles}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <div style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            verticalAlign: 'middle',
                                                        }}>
                                                            <CircleIcon style={{ color: zones[selectedDay] ? "green" : "orange" }} />
                                                            <p>{customer_name}</p>
                                                        </div>
                                                    </Card>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </Grid>

                    <Grid item style={gridItemStyle}>
                        <p>Mapped customers</p>
                        <Input placeholder={"Filter"} onChange={mappedCustomersFilterChange} value={mappedCustomersFilterValue} />
                        <Droppable droppableId="ZoneOrder">
                            {(provided) => (
                                <div style={cardContainerStyles} {...provided.droppableProps} ref={provided.innerRef}>
                                    {mappedCustomers.map(({_id, customer_name, order_taking_days}, index) => {
                                        return (
                                            <Draggable key={_id} draggableId={_id} index={index}>
                                                {(provided) => (
                                                    <Card
                                                        variant="outlined"
                                                        hidden={mappedCustomersFilterValue.length ? !customer_name.toLowerCase().includes(mappedCustomersFilterValue.toLowerCase()) : false}
                                                        style={cardStyles}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <div style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            verticalAlign: 'middle',
                                                        }}>
                                                            <CircleIcon style={{ color: order_taking_days.includes(selectedDay) ? "green" : "orange" }} />
                                                            <p>{customer_name}</p>
                                                        </div>
                                                    </Card>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </Grid>
                </Grid>
            </DragDropContext>
        }
    </div>
};
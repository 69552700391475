import React, {useEffect, useState} from "react";
import {
	HashRouter,
	Routes, // instead of "Switch"
	Route,
	Navigate
} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import { v4 as uuidv4 } from 'uuid';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Paper } from "@mui/material";
import NavBar from "./components/NavBar/NavBar";
import Invoices from "./pages/Invoice/Invoices"
import {Zone} from "./pages/Zone/Zone";
import {Section} from "./pages/Section/Section";
import {VAT} from "./pages/VAT/VAT";
import {Inventory} from "./pages/Inventory/Inventory";
import {InventoryTag} from "./pages/Inventory_Tag/InventoryTag";
import {InventoryCategory} from "./pages/Inventory_Category/InventoryCategory";
import {InventorySupplier} from "./pages/Inventory_Supplier/InventorySupplier";
import {CustomerTag} from "./pages/Customer_Tag/CustomerTag";
import {Customer} from "./pages/Customer/Customer";
import Dashboard from "./components/Dashboard/Dashboard";
import {SetCustomerZones} from "./pages/Customer/SetCustomerZones";
import {OrderTakingSheet} from "./pages/OrderTakingSheet/OrderTakingSheet";
import {Users} from "./pages/Users/Users";
import {SupplierInvoices} from "./pages/Supplier_Invoices/SupplierInvoices";
import {CustomerAccounts} from "./pages/CustomerAccounts/CustomerAccounts";
import {DriverName} from "./pages/Driver/DriverName";
import {DriverTotals} from "./pages/Driver/DriverTotals";
import {VehicleName} from "./pages/Driver/VehicleName";
import {CustomerSalesRep} from "./pages/CustomerSalesRep/CustomerSalesRep";
import {SetCustomerZonesV3} from "./pages/Customer/SetCustomerZonesV3";
import {PaymentTerm} from "./pages/Payment_Term/PaymentTerm";
import CollectionInvoices from "./pages/Collections/CollectionInvoices";

let initialPreferences = JSON.parse(localStorage.getItem("preferences"));

if(!initialPreferences) {
	const defaultPreferences = {
		theme: "dark"
	};
	localStorage.setItem("preferences", JSON.stringify(defaultPreferences));
	initialPreferences = defaultPreferences;
} else {
	initialPreferences.fontSize = Number(initialPreferences.fontSize);
}

export default function App() {
	const {isAuthenticated} = useAuth0();
	const [userPreferences, setUserPreferences] = useState(initialPreferences);
	const theme = React.useMemo(
		() =>
			createTheme({
				palette: {
					mode: userPreferences.theme,
				},
				// typography: {
				// 	fontSize: 15
				// }
			}),
		[userPreferences],
	);

	useEffect(() => {
		localStorage.setItem("preferences", JSON.stringify(userPreferences));
	}, [userPreferences]);

	const getNavBarComponent = component => {
		return <NavBar theme={theme} userPreferences={userPreferences} updateUserPreferences={setUserPreferences} renderComponent={component} />
	};

	const getConfigMappings = () => {
		return [
			<Route key={Math.random()} path={`/Setup/Zone`} element={getNavBarComponent(<Zone />)} />,
			<Route key={Math.random()} path={`/Setup/Section`} element={getNavBarComponent(<Section />)} />,
			<Route key={Math.random()} path={`/Setup/VAT`} element={getNavBarComponent(<VAT />)} />,
			<Route key={Math.random()} path={`/Setup/${encodeURIComponent("Customer Sales Rep")}`} element={getNavBarComponent(<CustomerSalesRep />)} />,
			<Route key={Math.random()} path={`/Setup/${encodeURIComponent("Payment Term")}`} element={getNavBarComponent(<PaymentTerm />)} />,
			<Route key={Math.random()} path={`/Inventory/${encodeURIComponent("Item List")}`} element={getNavBarComponent(<Inventory />)} />,
			<Route key={Math.random()} path={`/Inventory/${encodeURIComponent("Item Tags")}`} element={getNavBarComponent(<InventoryTag />)} />,
			<Route key={Math.random()} path={`/Inventory/${encodeURIComponent("Item Categories")}`} element={getNavBarComponent(<InventoryCategory />)} />,
			<Route key={Math.random()} path={`/Inventory/${encodeURIComponent("Item Suppliers")}`} element={getNavBarComponent(<InventorySupplier />)} />,
			<Route key={Math.random()} path={`/Inventory/${encodeURIComponent("Supplier Invoices")}`} element={getNavBarComponent(<SupplierInvoices />)} />,
			<Route key={Math.random()} path={`/Customer/${encodeURIComponent("Customer Tags")}`} element={getNavBarComponent(<CustomerTag />)} />,
			<Route key={Math.random()} path={`/Customer/${encodeURIComponent("Customer")}`} element={getNavBarComponent(<Customer />)} />,
			<Route key={Math.random()} path={`/Customer/${encodeURIComponent("Accounts")}`} element={getNavBarComponent(<CustomerAccounts />)} />,
			<Route key={Math.random()} path={`/Customer/${encodeURIComponent("Set Customer Zone")}`} element={getNavBarComponent(<SetCustomerZones />)} />,
			<Route key={Math.random()} path={`/Customer/${encodeURIComponent("Set Customer Zone v3")}`} element={getNavBarComponent(<SetCustomerZonesV3 />)} />,
			<Route key={Math.random()} path={`/Driver/${encodeURIComponent("Name")}`} element={getNavBarComponent(<DriverName />)} />,
			<Route key={Math.random()} path={`/Driver/${encodeURIComponent("Vehicle")}`} element={getNavBarComponent(<VehicleName />)} />,
			<Route key={Math.random()} path={`/Driver/${encodeURIComponent("Totals")}`} element={getNavBarComponent(<DriverTotals />)} />,
			<Route key={Math.random()} path={`/Invoice/${encodeURIComponent("Manage Invoices")}`} element={getNavBarComponent(<Invoices reduced={false} in_person={false} key={uuidv4()} />)} />,
			<Route key={Math.random()} path={`/Invoice/${encodeURIComponent("Invoices(limited)")}`} element={getNavBarComponent(<Invoices reduced={true} in_person={false} key={uuidv4()}/>)} />,
			<Route key={Math.random()} path={`/Invoice/${encodeURIComponent("Collections")}`} element={getNavBarComponent(<CollectionInvoices reduced={false} in_person={true} key={uuidv4()}/>)} />,
			<Route key={Math.random()} path={`/Invoice/${encodeURIComponent("Order Taking Sheet")}`} element={getNavBarComponent(<OrderTakingSheet />)} />,
			<Route key={Math.random()} path={`/Admin/${encodeURIComponent("Manage Users")}`} element={getNavBarComponent(<Users />)} />,
		];
	};

	return (
		<ThemeProvider theme={theme}>
				<HashRouter>
					<Paper variant="outlined" elevation={0} style={{ width: "100%", height: "100%" }}>
						<Routes>
							<Route exact path="/" element={getNavBarComponent(<Dashboard />)} />
							{
								isAuthenticated ?
									getConfigMappings()
									:
									<Route
										path="*"
										element={<Navigate to="/" />}
										key={Math.random()}
									/>
							}
						</Routes>
					</Paper>
				</HashRouter>
		</ThemeProvider>
	);
}

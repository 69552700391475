import React, {useEffect, useState} from 'react';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {Link} from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import {menuItems} from '../../configs/NavBarMenuItems';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import {Button, CssBaseline, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import SettingsIcon from '@mui/icons-material/Settings';
import {styled} from '@mui/material/styles';
import Profile from "../Profile/Profile";
import logo from "../../resources/spicedirect_logo.png";
import styles from "./styles.module.css";
import {useAuth0} from "@auth0/auth0-react";
import Cookies from 'universal-cookie';
import SettingsModal from "../SettingsModal/SettingsModal";
import jwtDecode from "jwt-decode";
import { useIdleTimer } from 'react-idle-timer'
import { io } from 'socket.io-client';
import {URL_ROOT} from "../../configs/config";

const drawerWidth = 240;

const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        height: "100%",
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const logoDivStyles = {
    height: "130px",
    minHeight: "130px"
};

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const NavBar = (props) => {
    const {isAuthenticated, isLoading, loginWithRedirect, logout} = useAuth0();
    const [collapseStates, setCollapseStates] = useState({});
    const [drawerOpen, setDrawerOpen] = useState(true);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [userPermissions, setUserPermissions] = useState(null);
    const [state, setState] = useState('Active');
    const [count, setCount] = useState(0);
    const [remaining, setRemaining] = useState(0);
    const [socketIO, setSocketIO] = useState(null);

    const onIdle = () => {
        setState('Idle');
        isAuthenticated && logoutUser();
    }

    const onActive = () => {
        setState('Active');
    }

    const {
        getRemainingTime,
        getTabId,
        isLeader,
        isLastActiveTab,
        message
    } = useIdleTimer({
        onIdle,
        onActive,
        timeout: process.env.REACT_APP_INACTIVITY_TIMEOUT_MILLISECONDS,
        crossTab: true,
        leaderElection: true,
        syncTimers: 200
    })

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000))
        }, 500)

        return () => {
            clearInterval(interval)
        }
    })

    const cookies = new Cookies();

    const logoutUser = () => {
        //remove token in cookies
        cookies.remove("apiToken");
        logout({returnTo: window.location.origin});
    }

    const handleClick = (name) => {
        const existingStateKeys = Object.keys(collapseStates);
        const existingStateAllClosed = {};
        existingStateKeys.forEach(key => {
            if(key !== name) {
                existingStateAllClosed[key] = false
            }
        });
        setCollapseStates({
            ...existingStateAllClosed,
            [name]: collapseStates[name] ? !collapseStates[name] : true
        });
    };

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const accessTokenCallback = token => {
        if(token && isAuthenticated) {
            const decodedToken = jwtDecode(token);
            setUserPermissions(decodedToken.permissions);
            const socket = io(URL_ROOT).connect();
            socket.emit("REGISTER", [decodedToken.sub, token]);
            socket.on("connect", () => socket.emit("REGISTER", [decodedToken.sub, token]))
            socket.on("LOGOUT", () => {
                socket.disconnect();
                logoutUser();
            });
            setSocketIO(socket);
        }
    };

    const hasPermission = (targetPermissions) => {
        return userPermissions?.some(item => targetPermissions.includes(item))
    };

    return <Box sx={{display: 'flex', height: "100%"}}>
        <CssBaseline/>
        <AppBar position="fixed" open={drawerOpen}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    sx={{mr: 2, ...(drawerOpen && {display: 'none'})}}
                >
                    <MenuIcon/>
                </IconButton>
                <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                    SPICE DIRECT LTD
                </Typography>
                {/*<span style={{marginRight: "2em"}}>{state}({remaining})</span>*/}
                <SettingsIcon onClick={() => setSettingsOpen(true)} style={{marginRight: "2em"}} />
                {settingsOpen && <SettingsModal userPreferences={props.userPreferences} updateUserPreferences={props.updateUserPreferences} handleClose={() => setSettingsOpen(false)} />}
                {
                    isAuthenticated &&
                    <Button variant="contained" onClick={logoutUser} endIcon={<LogoutIcon />}>
                        LOGOUT
                    </Button>
                }

            </Toolbar>
        </AppBar>
        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
            variant="persistent"
            anchor="left"
            open={drawerOpen}
        >
            <DrawerHeader style={logoDivStyles}>
                <img src={logo} style={{width: "100%", height: "100%"}} alt="Spice Direct Logo"/>
                <IconButton onClick={handleDrawerClose}>
                    {drawerOpen ? <ChevronLeftIcon/> : <ChevronRightIcon/>}
                </IconButton>
            </DrawerHeader>
            <Divider/>
            <Profile tokenCallback={accessTokenCallback} />
            <Divider/>
            {isAuthenticated ?
                <div>
                    {menuItems.map(parentItem =>
                        hasPermission(parentItem.requiredPermissions) &&
                        <>
                            <ListItemButton onClick={event => handleClick(parentItem.name)}
                                            key={parentItem.name}>
                                <ListItemIcon>
                                    {parentItem.icon}
                                </ListItemIcon>
                                <ListItemText primary={parentItem.name}/>
                                {collapseStates[parentItem.name] ? <ExpandLess/> : <ExpandMore/>}
                            </ListItemButton>
                            <Collapse in={collapseStates[parentItem.name]} timeout="auto" unmountOnExit>
                                {
                                    parentItem.subItems.map(subItem =>
                                        hasPermission(subItem.requiredPermissions) &&
                                        <List component="div" disablePadding>
                                            <Link to={`../${parentItem.name}/${subItem.name}`}
                                                  className={props.theme.palette.mode === "dark" ? styles.linkDark : styles.linkLight}>
                                                <ListItemButton sx={{pl: 4}}>
                                                    <ListItemIcon>
                                                        {subItem.icon}
                                                    </ListItemIcon>
                                                    <ListItemText primary={subItem.name}/>
                                                </ListItemButton>
                                            </Link>
                                        </List>
                                    )
                                }
                            </Collapse>
                        </>
                    )}
                    <Divider/>
                </div>
                :
                <List component="div" disablePadding>
                    <ListItemButton sx={{pl: 4}} onClick={() => loginWithRedirect()} disabled={isLoading}>
                        <ListItemIcon>
                            <LoginIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Login"/>
                    </ListItemButton>
                </List>
            }
            <Divider/>
        </Drawer>
        <Main open={drawerOpen}>
            <DrawerHeader/>
            {props.renderComponent}
        </Main>
    </Box>;
};

export default NavBar;
import React from 'react';
import IconButton from '@mui/material/IconButton';
import ReceiptIcon from '@mui/icons-material/Receipt';
import RestorePageIcon from '@mui/icons-material/RestorePage';
import PaymentsIcon from '@mui/icons-material/Payments';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import {currentUserHasPermissions} from "../formFunctions/FormFunctions";
import moment from "moment";

export const invoiceActionCellRenderer = (props) => {
	const INVOICE_EDIT_LIMIT = process.env.REACT_APP_INVOICES_EDIT_DURATION_LIMIT;
	const WRITE_PAYMENT_PERMISSIONS = [process.env.REACT_APP_WRITE_INVOICE_PAYMENTS_DATA_CLAIM];
	const todaysDate = moment(moment().format('YYYY-MM-DD'));
	const invoice_date = moment(moment(props.data.invoice_date).format('YYYY-MM-DD'));
	const daysSinceInvoiceDate = todaysDate.diff(invoice_date, 'days');

	const editAction = () => {
		props.editCB(props.data, daysSinceInvoiceDate < INVOICE_EDIT_LIMIT);
	};

	const paymentsAction = () => {
		props.openPayments(props.data);
	};

	const printInvoiceAction = () => {
		props.openInvoice && props.openInvoice(props.value);
	};

	const reprintInvoiceAction = () => {
		props.openInvoiceReprint && props.openInvoiceReprint(props.value);
	};

	const emailInvoiceAction = () => {
		if(props.emailInvoice) {
			if(window.confirm("Have you checked order and pricing?")) {
				props.emailInvoice(props.value);
			}
		}
	};

	return <Stack direction="row" spacing={1}>
		<Tooltip title={daysSinceInvoiceDate < INVOICE_EDIT_LIMIT ? "Edit" : "View"}>
			<IconButton aria-label="edit" onClick={editAction} disabled={!currentUserHasPermissions(props.requiredEditPermissions)}>
				{daysSinceInvoiceDate < INVOICE_EDIT_LIMIT ? <EditIcon/> : <VisibilityIcon/>}
			</IconButton>
		</Tooltip>
		{
			props.openInvoice &&
			<Tooltip title="Print Invoice">
				<IconButton aria-label="print invoice" onClick={printInvoiceAction}>
					<ReceiptIcon />
				</IconButton>
			</Tooltip>
		}
		{
			props.openInvoiceReprint &&
			<Tooltip title="Reprint Invoice">
				<IconButton aria-label="reprint invoice" onClick={reprintInvoiceAction}>
					<RestorePageIcon />
				</IconButton>
			</Tooltip>
		}
		{
			props.emailInvoice && <Tooltip title={props.data.email_sent ? "Email already sent, click to send again" : "Email Invoice"}>
				<IconButton aria-label={props.data.email_sent ? "Email already sent, click to send again" : "Email Invoice"} onClick={emailInvoiceAction}>
					{props.data.email_sent ? <MarkEmailReadIcon /> : <AttachEmailIcon />}
				</IconButton>
			</Tooltip>
		}

		{
			(currentUserHasPermissions(WRITE_PAYMENT_PERMISSIONS) && !props.reduced) &&
			<Tooltip title="Record Payment(s)">
				<IconButton aria-label="record payments" onClick={paymentsAction}>
					<PaymentsIcon />
				</IconButton>
			</Tooltip>
		}
	</Stack>;
}